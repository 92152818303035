import { FormFeedback, Label, Input } from "reactstrap";
import { ErrorMessage } from "@hookform/error-message";
import { buildValidation } from "../../support/helpers";

/**
 * Function for validating a field array input.
 *
 * @param {Object} assembly - the assembly object
 * @param {function} register - the register function
 * @param {string} name - the name of the field
 * @param {string} fieldArrName - the name of the field array input
 * @param {Object} errors - the errors object
 * @param {function} getValues - the getValues function
 * @param {number} currentIndex - the current index
 * @param {string} type - the type of input (defaults to "text")
 * @return {JSX.Element} the input component with label and error handling
 */
const ValidatingFieldArrInput = ({
    assembly,
    register,
    name,
    fieldArrName,
    errors,
    getValues,
    currentIndex,
    type = "text",
}) => {
    if (!assembly) return null;
    const assemblyItem = assembly.find((item) => item.id === name);
    const { ref, ...registerField } = register(
        fieldArrName,
        buildValidation(assembly, name, getValues)
    );

    return (
        <>
            <Label className={`mb-0`}>
                {assemblyItem?.fieldInfo.label}
                {assemblyItem?.requiredInfo?.required ? "*" : ""}
            </Label>
            <Input
                type={type}
                className="form-control"
                invalid={errors?.[currentIndex]?.[name] !== undefined}
                innerRef={ref}
                {...registerField}
            />
            {errors?.[currentIndex] && (
                <ErrorMessage
                    errors={errors?.[currentIndex]}
                    name={name}
                    render={({ message }) => (
                        <FormFeedback>{message}</FormFeedback>
                    )}
                />
            )}
        </>
    );
};

export default ValidatingFieldArrInput;
