import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Fetching from "../../Fetching";
import DiItems from "../../DisplayItems/DiItems";
import FeaturedAgent from "../../FeaturedAgent";
import Breadcrumbs from "../../Breadcrumbs";
import OffMarketBanner from "./OffMarketBanner";
import MegaAssociatesFilter from "./MegaAssociatesFilter";
import NicheItemMetrics from "./NicheItemMetrics";
import ShortBio from "./ShortBio";
import NicheListingTabs from "./NicheListingTabs";
import PageTitle from "../PageTitle/PageTitle";
import { useQueryParam, StringParam } from "use-query-params";
import { useNacLocation } from "../../../hooks/useNacLocation";

const Main = ({ isOffmarket, setShouldCluster, isDevelopment }) => {
    const [mapView] = useQueryParam("mapView", StringParam);
    const nicheItems = useSelector((state) => state.pages.nicheItems);
    const developments = useSelector((state) => state.pages.developments);
    const offmarket = useSelector((state) => state.pages.offmarket);

    const isListingsView = mapView === "listings";

    const { state: { nicheItemId, developmentId } = {} } = useNacLocation();
    const nicheItem = isOffmarket
        ? offmarket?.[nicheItemId]
        : isDevelopment
        ? developments?.[developmentId]
        : nicheItems?.[nicheItemId];
    const {
        headerLinks,
        displayName,
        stateName,
        developmentDesc,
        childNicheItems,
        servicingInfo,
    } = nicheItem || {};

    useEffect(() => {
        if (childNicheItems && !isListingsView) {
            setShouldCluster(false);
        } else {
            setShouldCluster(true);
        }
        return () => setShouldCluster(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isListingsView]);

    return (
        <Fetching waitUntil={nicheItem}>
            <div className="p-3" data-testid="niche-main">
                <Breadcrumbs className="pt-0" crumbs={headerLinks} />
                <OffMarketBanner isOffmarket={isOffmarket} />
                <PageTitle
                    className="mb-2"
                    data-test="nicheItem-title"
                    title={isDevelopment ? developmentDesc?.label : displayName}
                    subtitle={
                        (isDevelopment ? `${displayName}, ` : "") + stateName
                    }
                />
                <NicheItemMetrics isOffmarket={isOffmarket} />
                <ShortBio />
                {isDevelopment && (
                    <DiItems
                        items={[developmentDesc]}
                        wrapperClsses="d-inline-block mt-3"
                        labelClasses="d-none"
                        valueIsHtml={true}
                        dataTest="development-description"
                    />
                )}
                <FeaturedAgent
                    className="mt-3 mb-3"
                    dataTest="nicheItem-featured-agent"
                    showPhoneAsLink={true}
                    servicingInfo={servicingInfo}
                />
                {!isOffmarket && <MegaAssociatesFilter />}
                <NicheListingTabs isOffmarket={isOffmarket} />
            </div>
        </Fetching>
    );
};
export default Main;
