import React from "react";
import FieldArrayEditor from "../FieldArrayEditor";
import RestrictionsList from "./RestrictionList";

/**
 * Renders a RestrictionEditor component that wraps a FieldArrayEditor component.
 *
 * @param {Object} defaultContent - The default content for the editor.
 * @param {Function} save - The function to call when saving the editor.
 * @param {Function} cancel - The function to call when canceling the editor.
 * @return {JSX.Element} The rendered RestrictionEditor component.
 */
const RestrictionEditor = ({ defaultContent, save, cancel }) => {
    return (
        <FieldArrayEditor
            assemblyName="NICHE_ITEM_RESTRICTIONS"
            assemblyGroup="NICHE_ITEM_RESTRICTIONS"
            defaultContent={defaultContent}
            save={save}
            cancel={cancel}
            formatData={formatNicheRestrictionsData}
            mcType="restrictions"
            link="seeLink"
            linkText="value"
            description="labelSuffix"
            addButtonText="Add New Restriction"
        >
            {(props) => <RestrictionsList {...props} />}
        </FieldArrayEditor>
    );
};

const formatNicheRestrictionsData = (data) => {
    return data.restrictions.map((restriction) => ({
        id: "restrictions",
        disp: "V",
        botsDontFollow: 1,
        value: restriction.value,
        seeLink: restriction.seeLink,
        labelSuffix: restriction.labelSuffix,
    }));
};

export default RestrictionEditor;
