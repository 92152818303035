import React from "react";
import { Form, FormGroup, Button, Label, Input } from "reactstrap";
import IndeterminateButton from "../../IndeterminateButton";
import ValidatingField from "../../ValidatingField";
import { useForm, Controller } from "react-hook-form";
import AssemblyProvider from "../../AssemblyProvider";
import Loading from "../../Loading";
import {
    formatNicheInformationData,
    processInfoContent,
} from "../../../support/helpers";
import MultiSelect from "../../ReactSelect/MultiSelect";
import {
    useGetFishQuery,
    useGetCountiesQuery,
} from "../../../services/microcontentEndpoints";
import { useNacLocation } from "../../../hooks/useNacLocation";
import { useSelector } from "react-redux";

const assemblyName = "NICHE_ITEM_INFORMATION";
const assemblyGroup = "NICHE_ITEM_INFORMATION";

/**
 * InformationEditor Component.
 *
 * A form component for editing and submitting niche item information.
 * The form allows users to select counties, toggle availability of condos and sailing clubs, select types of fish present,
 * and input text for various descriptive fields. It retrieves necessary data from custom hooks and submits the processed
 * information through a provided save callback.
 *
 * @param {Object} defaultContent - The default content to populate the form with on initialization.
 * @param {function} save - Callback function that is called when form data is submitted.
 * @param {function} cancel - Callback function that is called when the user decides to cancel editing.
 */

const InformationEditor = ({ defaultContent, save, cancel }) => {
    const isBeach = useSelector(
        (state) => state.root.styling.nicheDomain2ndLevel === "beach-homes"
    );
    const {
        state: { stateId },
    } = useNacLocation();
    const { data: fishData, isError: fishError } = useGetFishQuery(
        {},
        { skip: isBeach }
    );
    const { data: countyData, isError: countyError } = useGetCountiesQuery({
        stateId,
    });
    const content = defaultContent?.information || defaultContent;
    let defaultValuesFromContent = processInfoContent(content);
    const informationForm = useForm({
        mode: "all",
        defaultValues: defaultValuesFromContent,
    });
    const { handleSubmit, getValues, control } = informationForm;

    const onSubmit = () => {
        save({ information: formatNicheInformationData(getValues()) });
    };

    const countyOptions = countyError
        ? [{ label: "Failed to get county options", value: "" }]
        : countyData?.counties.map((item) => ({
              label: item.name,
              value: item.name,
          })) || [];

    const fishOptions = fishError
        ? [{ label: "Failed to get fish options", value: "" }]
        : fishData?.fish.map((item) => ({
              label: item.name,
              value: item.name,
          })) || [];

    return (
        <AssemblyProvider group={assemblyGroup} name={assemblyName}>
            {(assembly, assemblyLoading) => (
                <>
                    {assemblyLoading ? (
                        <Loading />
                    ) : (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="d-flex align-items-center">
                                <div className="w-100">
                                    Counties
                                    <Controller
                                        name="counties"
                                        control={control}
                                        render={({ field }) => (
                                            <MultiSelect
                                                options={countyOptions}
                                                value={field.value}
                                                onChange={field.onChange}
                                                selectedOptions={field.value}
                                                setSelectedOptions={(val) =>
                                                    field.onChange(val)
                                                }
                                                defaultValue={
                                                    defaultValuesFromContent.counties
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="w-100 d-flex mt-3">
                                    <div className="me-3">Condos Available</div>
                                    <Controller
                                        name="condos"
                                        control={control}
                                        render={({ field }) => (
                                            <FormGroup tag="fieldset">
                                                <FormGroup check>
                                                    <Input
                                                        {...field}
                                                        type="radio"
                                                        value="Yes"
                                                        defaultChecked={
                                                            field.value ===
                                                            "Yes"
                                                        }
                                                    />
                                                    <Label check>Yes</Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        {...field}
                                                        type="radio"
                                                        value="No"
                                                        defaultChecked={
                                                            field.value === "No"
                                                        }
                                                    />
                                                    <Label check>No</Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        {...field}
                                                        type="radio"
                                                        value="hide"
                                                        defaultChecked={
                                                            field.value ===
                                                            "hide"
                                                        }
                                                    />
                                                    <Label check>Hide</Label>
                                                </FormGroup>
                                            </FormGroup>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="d-flex align-items-center mt-2">
                                {!isBeach && (
                                    <div className="w-100">
                                        Fish
                                        <Controller
                                            name="fish"
                                            control={control}
                                            render={({ field }) => (
                                                <MultiSelect
                                                    options={fishOptions}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    selectedOptions={
                                                        field.value
                                                    }
                                                    setSelectedOptions={(val) =>
                                                        field.onChange(val)
                                                    }
                                                    defaultValue={
                                                        defaultValuesFromContent.fish
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                                <div className="w-100 d-flex mt-3">
                                    <div className="me-5">Sailing Clubs</div>
                                    <Controller
                                        name="sailing_clubs"
                                        control={control}
                                        render={({ field }) => (
                                            <FormGroup tag="fieldset">
                                                <FormGroup check>
                                                    <Input
                                                        {...field}
                                                        type="radio"
                                                        value="Yes"
                                                        defaultChecked={
                                                            field.value ===
                                                            "Yes"
                                                        }
                                                    />
                                                    <Label check>Yes</Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        {...field}
                                                        type="radio"
                                                        value="No"
                                                        defaultChecked={
                                                            field.value === "No"
                                                        }
                                                    />
                                                    <Label check>No</Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        {...field}
                                                        type="radio"
                                                        value="hide"
                                                        defaultChecked={
                                                            field.value ===
                                                            "hide"
                                                        }
                                                    />
                                                    <Label check>Hide</Label>
                                                </FormGroup>
                                            </FormGroup>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <div className="w-75 me-5">
                                    <ValidatingField
                                        name="landmarks"
                                        type="textarea"
                                        assembly={assembly}
                                        {...informationForm}
                                    />
                                </div>
                                <div className="w-75 me-5">
                                    <ValidatingField
                                        name="known_for"
                                        type="textarea"
                                        assembly={assembly}
                                        {...informationForm}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="w-75 me-5">
                                    <ValidatingField
                                        className="w-100 me-2"
                                        name="notable_events"
                                        type="textarea"
                                        assembly={assembly}
                                        {...informationForm}
                                    />
                                </div>
                                <div className="w-75 me-5">
                                    <ValidatingField
                                        className="w-100 me-2"
                                        name="interesting_facts"
                                        type="textarea"
                                        assembly={assembly}
                                        {...informationForm}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="w-75 me-5">
                                    <ValidatingField
                                        className="w-100 me-2"
                                        name="history"
                                        type="textarea"
                                        assembly={assembly}
                                        {...informationForm}
                                    />
                                </div>
                                <div className="w-75 me-5">
                                    <ValidatingField
                                        className="w-100 me-2"
                                        name="other_notes"
                                        type="textarea"
                                        assembly={assembly}
                                        {...informationForm}
                                    />
                                </div>
                            </div>
                            <FormGroup className="d-flex my-3">
                                <Button
                                    color="link"
                                    className="ms-auto"
                                    onClick={() => cancel()}
                                >
                                    Cancel
                                </Button>
                                <IndeterminateButton type="submit">
                                    Save
                                </IndeterminateButton>
                            </FormGroup>
                        </Form>
                    )}
                </>
            )}
        </AssemblyProvider>
    );
};

export default InformationEditor;
