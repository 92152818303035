import React from "react";
import AssemblyProvider from "../../AssemblyProvider";
import Loading from "../../Loading";
import LakeHomes from "./LakeHomes";
import BeachHomes from "./BeachHomes";
import {
    buildDataForOverviewAssembly,
    useOverviewEditor,
} from "./hooks/useOverviewEditor";

const OverviewEditor = ({ defaultContent, save, cancel }) => {
    const { nicheName, assemblyName, assemblyGroup } = useOverviewEditor({
        defaultContent,
    });

    // This will need to be turned into a map when we add a third niche
    const NicheOverview = nicheName === "beach-homes" ? BeachHomes : LakeHomes;

    return (
        <AssemblyProvider
            group={assemblyGroup}
            name={assemblyName}
            ignoreCache={true}
            transformData={() =>
                buildDataForOverviewAssembly(
                    defaultContent?.overview || defaultContent
                )
            }
        >
            {(assembly, assemblyLoading) => (
                <>
                    {assemblyLoading ? (
                        <Loading />
                    ) : (
                        <NicheOverview
                            assembly={assembly}
                            save={save}
                            cancel={cancel}
                            defaultContent={defaultContent}
                        />
                    )}
                </>
            )}
        </AssemblyProvider>
    );
};

export default OverviewEditor;
