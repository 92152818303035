import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Fetching from "../../Fetching";
import DiItems from "../../DisplayItems/DiItems";
import EditorWrapper from "../../Editor/EditorWrapper";
import RichTextEditor from "../../Editor/RichTextEditor";
import { useGetAgent } from "../../../hooks/agent";
import { addAgentPage } from "../../../reducers/pagesReducer";
import { useNacLocation } from "../../../hooks/useNacLocation";

const Bottom = memo(() => {
    const dispatch = useDispatch();
    const agents = useSelector((state) => state.pages.agents);
    const { state: { agentId, stateId, nicheItemId } = {} } = useNacLocation();
    const { execute: getAgent } = useGetAgent();
    const agent = agents?.[agentId]?.data;
    const { agentInfo = {} } = agent || {};

    return (
        <Fetching waitUntil={agent}>
            <div data-testid="agent-bottom">
                <div className="container-lg mt-3">
                    <div className="row g-0">
                        <div className="col g-0 full-bio">
                            <EditorWrapper
                                category="AGENTS"
                                item="Long-Bio"
                                id={agentId}
                                EditorComponent={RichTextEditor}
                                defaultContent={
                                    agentInfo?.agent_description?.value
                                }
                                publishCallback={async () => {
                                    const response = await getAgent(
                                        agentId,
                                        stateId,
                                        nicheItemId,
                                        { gispreview: true }
                                    );
                                    dispatch(
                                        addAgentPage({
                                            id: agentId,
                                            data: response,
                                        })
                                    );
                                }}
                            >
                                <DiItems
                                    items={[agentInfo?.agent_description]}
                                    wrapperClsses="d-inline-block me-4"
                                    labelClasses="h4 d-block"
                                    valueIsHtml={true}
                                />
                            </EditorWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </Fetching>
    );
});

export default Bottom;
