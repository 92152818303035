import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAssembly } from "../../../../reducers/rootReducer";
import { stringToNumber } from "../../../../support/helpers";

const assemblyName = "NICHE_ITEM_OVERVIEW";
const assemblyGroup = "NICHE_ITEM_OVERVIEW";

export const useOverviewEditor = ({ defaultContent }) => {
    const dispatch = useDispatch();
    const nicheName = useSelector(
        (state) => state.root.styling.nicheDomain2ndLevel
    );

    useEffect(() => {
        // Removing assebly so that it will re-run the transformData function
        dispatch(removeAssembly(assemblyName));
    }, [defaultContent]);

    return {
        nicheName,
        assemblyName,
        assemblyGroup,
    };
};

/**
 * Mapping functions for overview data.
 */
export const overviewDataMap = {
    shoreline_miles: (value) => stringToNumber(value),
    lake_depth_avg: (value) => stringToNumber(value),
    lake_depth_max: (value) => stringToNumber(value),
    public_boat_ramps: (value) => stringToNumber(value),
    acreage: (value) => stringToNumber(value),
    water_drop: (value) => stringToNumber(value.replaceAll(" ft", "")),
};

/**
 * Creates data property that gets appended to overview assembly.
 * @param {Object[]} defaultContent Overview data.
 * @returns {Object} An object with reformatted overview data.
 */
export const buildDataForOverviewAssembly = (defaultContent) => {
    const data = defaultContent.reduce((result, obj) => {
        if (obj.id === "shoreline_border") {
            const mappings = {
                "State Park": "state_shoreline_border",
                "National Park": "national_shoreline_border",
                Mountains: "mountain_shoreline_border",
            };
            const brTagRegex = /<br\s*\/?>/;
            const transformedData = obj.value
                .split(brTagRegex)
                .reduce((acc, item) => {
                    let [key, value] = item.split(" (");
                    if (value) {
                        value = value.replace(")", "");
                        if (mappings[key]) {
                            acc[mappings[key]] = value;
                        }
                    } else {
                        acc[mappings[key]] = "All";
                    }
                    return acc;
                }, {});
            Object.assign(result, transformedData);
        } else if (obj.id === "full_pool") {
            const [schedule_start, schedule_end] = obj.value.split("-");
            result["schedule_start"] = schedule_start.trim();
            result["schedule_end"] = schedule_end.trim();
        } else if (obj.id === "managedBy") {
            result[obj.id] = obj.value;
            if (obj?.valueLink) {
                result.operated_by_link = obj?.valueLink;
            }
        } else {
            result[obj.id] = overviewDataMap[obj.id]
                ? overviewDataMap[obj.id](obj.value)
                : obj.value;
        }
        return result;
    }, {});
    return data;
};
