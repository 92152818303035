import React from "react";
import FieldArrayEditor from "../FieldArrayEditor";
import ResourceList from "./ResourceList";

/**
 * Renders a ResourceEditor component that wraps a FieldArrayEditor component.
 *
 * @param {Object} defaultContent - The default content for the editor.
 * @param {Function} save - The function to call when saving the editor.
 * @param {Function} cancel - The function to call when canceling the editor.
 * @return {JSX.Element} The rendered ResourceEditor component.
 */
const ResourceEditor = ({ defaultContent, save, cancel }) => {
    return (
        <FieldArrayEditor
            assemblyName="NICHE_ITEM_RESOURCES"
            assemblyGroup="NICHE_ITEM_RESOURCES"
            defaultContent={defaultContent}
            save={save}
            cancel={cancel}
            formatData={formatNicheResourceData}
            mcType="resources"
            link="link"
            linkText="link_text"
            description="description"
            addButtonText="Add New Resource"
        >
            {(props) => <ResourceList {...props} />}
        </FieldArrayEditor>
    );
};

const formatNicheResourceData = (data) => {
    return data.resources.map((resource) => ({
        id: "resource",
        disp: "V",
        botsDontFollow: 1,
        value: resource.link_text,
        valueLink: resource.link,
        labelSuffix: resource.description,
    }));
};

export default ResourceEditor;
