import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Form, FormGroup, Button } from "reactstrap";
import IndeterminateButton from "../../IndeterminateButton";
import { useDispatch } from "react-redux";
import AssemblyProvider from "../../AssemblyProvider";
import Loading from "../../Loading";
import { removeAssembly } from "../../../reducers/rootReducer";

/**
 * Editor for a React Hook Form's field array
 *
 * @param {string} assemblyName - the name of the assembly
 * @param {string} assemblyGroup - the group of the assembly
 * @param {Object} defaultContent - the default content for the editor
 * @param {Function} save - the function to save the edited fields
 * @param {Function} cancel - the function to cancel the editing process
 * @param {Function} formatData - the function to format the data before saving
 * @param {string} mcType - the name of the field array in the form
 * @return {JSX.Element} The field array editor component
 */
const FieldArrayEditor = ({
    assemblyName,
    assemblyGroup,
    defaultContent,
    save,
    cancel,
    formatData,
    mcType,
    link,
    linkText,
    description,
    children,
    addButtonText,
}) => {
    const dispatch = useDispatch();
    const content = defaultContent?.[mcType] || defaultContent;
    const editorForm = useForm({
        mode: "all",
        defaultValues: {
            [mcType]: (content || []).map((dc) => ({
                [linkText]: dc.value,
                [link]: dc.valueLink || dc.seeLink,
                [description]: dc.labelSuffix,
            })),
        },
    });

    const { control, handleSubmit } = editorForm;
    const { fields, append, remove } = useFieldArray({
        control,
        name: mcType,
    });

    useEffect(() => {
        dispatch(removeAssembly(assemblyName));
    }, [dispatch, defaultContent]);

    const onSubmit = (data) => {
        save({ [mcType]: formatData(data) });
    };

    // Adds an empty field set if there are no fields
    useEffect(() => {
        if (content.length < 1 || !content) {
            append({ link_text: "", link: "", description: "" });
        }
    }, []);

    return (
        <AssemblyProvider group={assemblyGroup} name={assemblyName}>
            {(assembly, assemblyLoading) => (
                <>
                    {assemblyLoading ? (
                        <Loading />
                    ) : (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {children({
                                fields,
                                assembly,
                                mcType,
                                remove,
                                ...editorForm,
                            })}
                            <FormGroup className="d-flex justify-content-end">
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={() =>
                                        append({
                                            link_text: "",
                                            link: "",
                                            description: "",
                                        })
                                    }
                                >
                                    {addButtonText}
                                </Button>
                            </FormGroup>
                            <FormGroup className="d-flex my-3">
                                <Button
                                    type="button"
                                    color="link"
                                    className="ms-auto"
                                    onClick={cancel}
                                >
                                    Cancel
                                </Button>
                                <IndeterminateButton type="submit">
                                    Save
                                </IndeterminateButton>
                            </FormGroup>
                        </Form>
                    )}
                </>
            )}
        </AssemblyProvider>
    );
};

export default FieldArrayEditor;
