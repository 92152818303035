import styled from "styled-components";
import ValidatingField from "../../../ValidatingField";

export const NumberField = styled(ValidatingField)`
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
`;
