import React, { useEffect } from "react";
import { AsyncSelect } from "./ReactSelect";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";

/**
 * MultiSelect Component.
 *
 * A custom multi-select dropdown component built using React, styled-components, and React Select's AsyncSelect.
 * It allows users to select multiple options from an asynchronous dropdown list. Selected options are displayed below the dropdown.
 * Users can remove selected options by clicking the close (x) icon on each tag.
 *
 * @param {Object[]} options - The list of options that the user can select from.
 * @param {Object[]} selectedOptions - The current array of selected options.
 * @param {function} setSelectedOptions - The function to update the selected options.
 * @param {Object[]} defaultValue - The default set of options selected when the component mounts.
 * @param {function} onChange - Callback function that is called with the new set of selected options whenever it changes.
 */

const MultiSelect = ({
    options,
    selectedOptions,
    setSelectedOptions,
    defaultValue,
    onChange,
}) => {
    const handleChange = (values) => {
        setSelectedOptions(values);
    };

    const handleRemoveItem = (itemToRemove) => {
        const updatedOptions = selectedOptions.filter(
            (item) => item.value !== itemToRemove.value
        );
        setSelectedOptions(updatedOptions);
        onChange(updatedOptions);
    };

    const loadOptions = (inputValue, callback) => {
        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );

        callback(filteredOptions);
    };

    useEffect(() => {
        setSelectedOptions(defaultValue);
    }, []);

    return (
        <>
            <AsyncSelect
                isMulti
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                onChange={handleChange}
                value={selectedOptions}
                className="basic-multi-select w-75"
                classNamePrefix="select"
                defaultValue={defaultValue}
                defaultOptions={options}
                loadOptions={loadOptions}
                styles={{
                    clearIndicator: (baseStyles) => ({
                        ...baseStyles,
                        display: "none",
                    }),
                    dropdownIndicator: ({ padding, ...baseStyles }) => ({
                        ...baseStyles,
                        color: "var(--black)",
                    }),
                    loadingIndicator: () => ({ display: "none" }),
                    indicatorSeparator: (baseStyles) => ({
                        ...baseStyles,
                        display: "none",
                    }),
                }}
            />
            <div className="d-flex flex-wrap">
                {selectedOptions?.length > 0 &&
                    selectedOptions.map((item) => (
                        <StyledSelectedOptions
                            key={item.value}
                            className="me-2 my-1 px-1 d-flex align-content-center"
                        >
                            <div className="me-1">{item.label}</div>
                            <div
                                onClick={() => handleRemoveItem(item)}
                                style={{ cursor: "pointer" }}
                            >
                                <IoMdClose size={16} />
                            </div>
                        </StyledSelectedOptions>
                    ))}
            </div>
        </>
    );
};

const StyledSelectedOptions = styled.div`
    color: var(--bs-primary);
    background-color: var(--bs-gray-200);
    border-radius: 4px;
`;

export default MultiSelect;
