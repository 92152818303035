import React from "react";
import { Row, Col, Button } from "reactstrap";
import ValidatingFieldArrInput from "../../../ValidatingFieldArrInput";

const ResourceList = ({
    fields,
    mcType,
    assembly,
    register,
    formState: { errors },
    getValues,
    remove,
}) => {
    return (
        <>
            {fields.map((field, index) => (
                <Row
                    key={field.id}
                    className="d-flex justify-content-center my-2"
                >
                    <Col>
                        <ValidatingFieldArrInput
                            assembly={assembly}
                            register={register}
                            fieldArrName={`${mcType}.${index}.link_text`}
                            name="link_text"
                            errors={errors?.[mcType]}
                            getValues={getValues}
                            currentIndex={index}
                        />
                    </Col>
                    <Col>
                        <ValidatingFieldArrInput
                            assembly={assembly}
                            register={register}
                            fieldArrName={`${mcType}.${index}.link`}
                            name="link"
                            errors={errors?.[mcType]}
                            getValues={getValues}
                            currentIndex={index}
                            type="textarea"
                        />
                    </Col>
                    <Col>
                        <ValidatingFieldArrInput
                            assembly={assembly}
                            register={register}
                            fieldArrName={`${mcType}.${index}.description`}
                            name="description"
                            errors={errors?.[mcType]}
                            getValues={getValues}
                            currentIndex={index}
                            type="textarea"
                        />
                    </Col>
                    <Col className="my-auto">
                        <Button color="danger" onClick={() => remove(index)}>
                            Delete
                        </Button>
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default ResourceList;
