import React from "react";
import { Row, Col, Form, FormGroup, Button } from "reactstrap";
import IndeterminateButton from "../../../IndeterminateButton";
import ValidatingField from "../../../ValidatingField";
import { NumberField } from "../NumberField";
import { useBeachHomes } from "./hooks/useBeachHomes";

const BeachHomes = ({ defaultContent, assembly, save, cancel }) => {
    const { onSubmit, handleSubmit, overviewForm } = useBeachHomes({
        defaultContent,
        assembly,
        save,
    });
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-4 justify-content-around">
                <Col className=" d-flex justify-content-center">
                    <NumberField
                        className="mb-3 d-flex align-items-center"
                        formFieldClasses="mx-2"
                        labelClasses="fw-bold text-nowrap"
                        formFieldStyles={{ width: "100px" }}
                        type="number"
                        name="shoreline_miles"
                        suffix={<div className="fw-bold">mi</div>}
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
                <Col className=" d-flex justify-content-center">
                    <NumberField
                        className="mb-3 d-flex align-items-center"
                        formFieldClasses="mx-2"
                        labelClasses="fw-bold text-nowrap"
                        formFieldStyles={{ width: "100px" }}
                        suffix={<div className="fw-bold">&deg;F</div>}
                        type="number"
                        name="high_temp_avg"
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
                <Col className=" d-flex justify-content-center">
                    <NumberField
                        className="mb-3 d-flex align-items-center"
                        formFieldClasses="mx-2"
                        labelClasses="fw-bold text-nowrap"
                        formFieldStyles={{ width: "100px" }}
                        suffix={<div className="fw-bold">&deg;F</div>}
                        type="number"
                        name="low_temp_avg"
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <span className="h5">Shoreline Borders</span>
                    <ValidatingField
                        name="state_shoreline_border"
                        assembly={assembly}
                        type="select"
                        {...overviewForm}
                    />
                    <ValidatingField
                        name="national_shoreline_border"
                        assembly={assembly}
                        type="select"
                        {...overviewForm}
                    />
                </Col>
                <Col sm={8}>
                    <span className="h5">Miscellaneous</span>
                    <div className="d-flex flex-wrap">
                        <div className="w-50 px-3">
                            <NumberField
                                className="d-flex flex-wrap align-items-center mb-3"
                                formFieldClasses="me-2 w-50"
                                labelClasses="flex-grow-1 w-100"
                                suffix={<div>ramps</div>}
                                type="number"
                                name="public_boat_ramps"
                                assembly={assembly}
                                {...overviewForm}
                            />
                            <ValidatingField
                                name="primary_composition"
                                assembly={assembly}
                                type="select"
                                {...overviewForm}
                            />
                        </div>
                        <div className="w-50 px-3">
                            <ValidatingField
                                name="pier"
                                assembly={assembly}
                                type="select"
                                {...overviewForm}
                            />
                            <ValidatingField
                                name="public_website"
                                assembly={assembly}
                                {...overviewForm}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <FormGroup className="d-flex my-3">
                <Button
                    color="link"
                    className="ms-auto"
                    onClick={() => cancel()}
                >
                    Cancel
                </Button>
                <IndeterminateButton type="submit">Save</IndeterminateButton>
            </FormGroup>
        </Form>
    );
};

export default BeachHomes;
