import React from "react";
import { Row, Col, Form, FormGroup, Button } from "reactstrap";
import IndeterminateButton from "../../../IndeterminateButton";
import ValidatingField from "../../../ValidatingField";
import { NumberField } from "../NumberField";
import { useLakeHomes } from "./hooks/useLakeHomes";

const LakeHomes = ({ defaultContent, assembly, save, cancel }) => {
    const { onSubmit, handleSubmit, watch, overviewForm } = useLakeHomes({
        defaultContent,
        assembly,
        save,
    });
    const dam_name = watch("dam_name");
    const managedBy = watch("managedBy");

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-4 justify-content-around">
                <Col className=" d-flex justify-content-center">
                    <NumberField
                        className="mb-3 d-flex align-items-center"
                        formFieldClasses="mx-2"
                        labelClasses="fw-bold text-nowrap"
                        formFieldStyles={{ width: "100px" }}
                        type="number"
                        name="shoreline_miles"
                        suffix={<div className="fw-bold">mi</div>}
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
                <Col className=" d-flex justify-content-center">
                    <NumberField
                        className="mb-3 d-flex align-items-center"
                        formFieldClasses="mx-2"
                        labelClasses="fw-bold text-nowrap"
                        formFieldStyles={{ width: "100px" }}
                        suffix={<div className="fw-bold">ft</div>}
                        type="number"
                        name="lake_depth_avg"
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
                <Col className=" d-flex justify-content-center">
                    <NumberField
                        className="mb-3 d-flex align-items-center"
                        formFieldClasses="mx-2"
                        labelClasses="fw-bold text-nowrap"
                        formFieldStyles={{ width: "100px" }}
                        suffix={<div className="fw-bold">ft</div>}
                        type="number"
                        name="lake_depth_max"
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <span className="h5">Shoreline Borders</span>
                    <ValidatingField
                        name="state_shoreline_border"
                        assembly={assembly}
                        type="select"
                        {...overviewForm}
                    />
                    <ValidatingField
                        name="national_shoreline_border"
                        assembly={assembly}
                        type="select"
                        {...overviewForm}
                    />
                    <ValidatingField
                        name="mountain_shoreline_border"
                        assembly={assembly}
                        type="select"
                        {...overviewForm}
                    />
                </Col>
                <Col>
                    <span className="h5">Dam</span>
                    <div className="d-flex mb-3">
                        <ValidatingField
                            className="w-100 me-2"
                            name="dam_name"
                            assembly={assembly}
                            {...overviewForm}
                        />
                        <NumberField
                            className="w-100"
                            type="number"
                            name="year_built"
                            disabled={!dam_name}
                            assembly={assembly}
                            {...overviewForm}
                        />
                    </div>
                    <span className="h5">Managed By</span>
                    <ValidatingField
                        name="managedBy"
                        assembly={assembly}
                        {...overviewForm}
                    />
                    <ValidatingField
                        name="operated_by_link"
                        disabled={!managedBy}
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
                <Col>
                    <span className="h5">Water</span>
                    <ValidatingField
                        name="scheduled_water_level"
                        assembly={assembly}
                        type="select"
                        {...overviewForm}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                        <ValidatingField
                            className="w-100"
                            name="schedule_start"
                            assembly={assembly}
                            type="select"
                            {...overviewForm}
                        />
                        <span className="mx-3 mt-3 align-middle fw-bold">
                            To
                        </span>
                        <ValidatingField
                            className="w-100"
                            name="schedule_end"
                            assembly={assembly}
                            type="select"
                            {...overviewForm}
                        />
                    </div>
                    <NumberField
                        className="mt-3 d-flex flex-wrap align-items-center"
                        formFieldClasses="me-2 w-50"
                        labelClasses="flex-grow-1 w-100"
                        suffix={<div>ft</div>}
                        type="number"
                        name="water_drop"
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
            </Row>
            <Row>
                <span className="h5">Miscellaneous</span>
                <Col sm="2">
                    <NumberField
                        className="d-flex flex-wrap align-items-center"
                        formFieldClasses="me-2 w-50"
                        labelClasses="flex-grow-1 w-100"
                        suffix={<div>ramps</div>}
                        type="number"
                        name="public_boat_ramps"
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
                <Col sm="2">
                    <NumberField
                        className="d-flex flex-wrap align-items-center"
                        formFieldClasses="me-2 w-50"
                        labelClasses="flex-grow-1 w-100"
                        suffix={<div>acres</div>}
                        type="number"
                        name="acreage"
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
                <Col sm="2">
                    <ValidatingField
                        formFieldClasses="w-50"
                        name="ownership"
                        type="select"
                        assembly={assembly}
                        {...overviewForm}
                    />
                </Col>
            </Row>
            <FormGroup className="d-flex my-3">
                <Button
                    color="link"
                    className="ms-auto"
                    onClick={() => cancel()}
                >
                    Cancel
                </Button>
                <IndeterminateButton type="submit">Save</IndeterminateButton>
            </FormGroup>
        </Form>
    );
};

export default LakeHomes;
