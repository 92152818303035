import React from "react";
import { Row, Col, Button } from "reactstrap";
import ValidatingFieldArrInput from "../../../ValidatingFieldArrInput";

const RestrictionsList = ({
    fields,
    mcType,
    assembly,
    register,
    formState: { errors },
    getValues,
    remove,
}) => {
    return (
        <>
            {fields.map((field, index) => (
                <Row
                    key={field.id}
                    className="d-flex justify-content-center my-2"
                >
                    <Col>
                        <ValidatingFieldArrInput
                            assembly={assembly}
                            register={register}
                            fieldArrName={`${mcType}.${index}.value`}
                            name="value"
                            errors={errors?.[mcType]}
                            getValues={getValues}
                            currentIndex={index}
                        />
                    </Col>
                    <Col>
                        <ValidatingFieldArrInput
                            assembly={assembly}
                            register={register}
                            fieldArrName={`${mcType}.${index}.seeLink`}
                            name="seeLink"
                            errors={errors?.[mcType]}
                            getValues={getValues}
                            currentIndex={index}
                            type="textarea"
                        />
                    </Col>
                    <Col>
                        <ValidatingFieldArrInput
                            assembly={assembly}
                            register={register}
                            fieldArrName={`${mcType}.${index}.labelSuffix`}
                            name="labelSuffix"
                            errors={errors?.[mcType]}
                            getValues={getValues}
                            currentIndex={index}
                            type="textarea"
                        />
                    </Col>
                    <Col className="my-auto">
                        <Button color="danger" onClick={() => remove(index)}>
                            Delete
                        </Button>
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default RestrictionsList;
