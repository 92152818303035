import { useForm } from "react-hook-form";
import { overviewDataMap } from "../../hooks/useOverviewEditor";
import { numberWithCommas } from "../../../../../support/helpers";

export const useBeachHomes = ({ defaultContent, assembly, save }) => {
    const content = defaultContent?.overview || defaultContent;
    const defaultValuesFromContent =
        content.reduce((acc, item) => {
            acc[item.id] = overviewDataMap[item.id]
                ? overviewDataMap[item.id](item.value)
                : item.value;
            return acc;
        }, {}) || {};
    const overviewForm = useForm({
        mode: "all",
        defaultValues: defaultValuesFromContent,
    });
    const { handleSubmit, getValues } = overviewForm;

    const onSubmit = () => {
        save({ overview: formatNicheOverviewData(getValues(), assembly) });
    };

    return { onSubmit, handleSubmit, overviewForm };
};

/**
 * Formats the given data to provide a summary of the shoreline borders,
 * adding additional display information from the assembly.
 * @param {Object} data The input data with information about different shoreline borders.
 * @param {Object[]} assembly Assembly that contains displayInfo properties.
 * @returns {Object[]} A formatted array containing data combined with display information.
 */
const formatNicheOverviewData = (data, assembly) => {
    const formattedData = [];
    if (data?.state_shoreline_border || data?.national_shoreline_border) {
        // shorline_border display info lives in the state_shoreline_border so grabbing that here
        const displayInfo =
            assembly.find((x) => x.id === "state_shoreline_border")
                ?.displayInfo || {};
        let valueString = "";
        if (data?.state_shoreline_border)
            valueString += `State Park (${data?.state_shoreline_border})<br/>`;
        if (data?.national_shoreline_border)
            valueString += `National Park (${data?.national_shoreline_border})<br/>`;

        // Remove the last <br /> from valueString
        valueString = valueString.slice(0, -5);
        formattedData.push({
            ...displayInfo,
            id: "shoreline_border",
            value: valueString,
        });
    }

    const skip = [
        "operated_by_link",
        "schedule_start",
        "schedule_end",
        "state_shoreline_border",
        "national_shoreline_border",
        "mountain_shoreline_border",
        "shoreline_border",
        "full_pool",
        "ownership",
    ];

    for (let [key, val] of Object.entries(data)) {
        if (val && skip.indexOf(key) === -1) {
            let displayInfo =
                assembly.find((x) => x.id === key)?.displayInfo || {};
            // add commas to numbers
            if (Object.keys(overviewDataMap).includes(key)) {
                val = numberWithCommas(val);
            }

            if (key === "public_website") {
                const link = data?.public_website.startsWith("http")
                    ? data?.public_website
                    : `https://${data?.public_website}`;
                displayInfo = {
                    valueLink: link,
                    label: "Public Website",
                    ...displayInfo,
                };
            }
            formattedData.push({
                id: key,
                value: val,
                ...displayInfo,
            });
        }
    }

    return formattedData;
};
