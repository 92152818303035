import { useForm } from "react-hook-form";
import { overviewDataMap } from "../../hooks/useOverviewEditor";
import { numberWithCommas } from "../../../../../support/helpers";

export const useLakeHomes = ({ defaultContent, assembly, save }) => {
    const content = defaultContent?.overview || defaultContent;
    const defaultValuesFromContent = getDefaultValues(content, assembly);
    const overviewForm = useForm({
        mode: "all",
        defaultValues: defaultValuesFromContent,
    });
    const { handleSubmit, watch } = overviewForm;

    const onSubmit = (data) => {
        save({ overview: formatNicheOverviewData(data, assembly) });
    };

    return {
        onSubmit,
        handleSubmit,
        overviewForm,
        watch,
    };
};

/**
 * Formats the given data to provide a summary of the shoreline borders,
 * adding additional display information from the assembly.
 * @param {Object} data The input data with information about different shoreline borders.
 * @param {Object[]} assembly Assembly that contains displayInfo properties.
 * @returns {Object[]} A formatted array containing data combined with display information.
 */
export const formatNicheOverviewData = (data, assembly) => {
    const formattedData = [];
    if (
        data?.state_shoreline_border ||
        data?.national_shoreline_border ||
        data?.mountain_shoreline_border
    ) {
        // shorline_border display info lives in the state_shoreline_border so grabbing that here
        const displayInfo =
            assembly.find((x) => x.id === "state_shoreline_border")
                ?.displayInfo || {};
        let valueString = "";
        if (data?.state_shoreline_border)
            valueString += `State Park (${data?.state_shoreline_border})<br/>`;
        if (data?.national_shoreline_border)
            valueString += `National Park (${data?.national_shoreline_border})<br/>`;
        if (data?.mountain_shoreline_border)
            valueString += `Mountains (${data?.mountain_shoreline_border})<br/>`;
        // Remove the last <br /> from valueString
        valueString = valueString.slice(0, -5);
        formattedData.push({
            ...displayInfo,
            id: "shoreline_border",
            value: valueString,
        });
    }

    const skip = [
        "operated_by_link",
        "schedule_start",
        "schedule_end",
        "state_shoreline_border",
        "national_shoreline_border",
        "mountain_shoreline_border",
        "shoreline_border",
        "full_pool",
        "ownership",
    ];

    for (let [key, val] of Object.entries(data)) {
        if (val && skip.indexOf(key) === -1) {
            let displayInfo =
                assembly.find((x) => x.id === key)?.displayInfo || {};
            // add commas to numbers
            if (Object.keys(overviewDataMap).includes(key)) {
                val = numberWithCommas(val);
            }

            if (key === "managedBy" && data?.["operated_by_link"]) {
                const link = data?.operated_by_link.startsWith("http")
                    ? data?.operated_by_link
                    : `https://${data?.operated_by_link}`;
                displayInfo = { valueLink: link, ...displayInfo };
            }
            if (key === "acreage") {
                displayInfo = { value: val, ...displayInfo };
            }
            if (key === "water_drop") {
                displayInfo = { value: `${val} ft`, ...displayInfo };
            }
            formattedData.push({
                id: key,
                value: val,
                ...displayInfo,
            });
        }
    }

    if (data?.ownership) {
        formattedData.push({
            id: "ownership",
            label: "Ownership",
            value: data["ownership"],
        });
    }

    if (data?.schedule_start && data?.schedule_end) {
        // full_pool display info lives in schedule_start so grabbing that here
        const displayInfo =
            assembly.find((x) => x.id === "schedule_start")?.displayInfo || {};
        formattedData.push({
            ...displayInfo,
            id: "full_pool",
            value: `${data["schedule_start"]} - ${data["schedule_end"]}`,
        });
    }
    return formattedData;
};

const getDefaultValues = (content, assembly) => {
    let defaultValues =
        content.reduce((acc, item) => {
            if (item.id === "shoreline_border") {
                const mappings = {
                    "State Park": "state_shoreline_border",
                    "National Park": "national_shoreline_border",
                    Mountains: "mountain_shoreline_border",
                };
                const brTagRegex = /<br\s*\/?>/;
                const transformedData = item.value
                    .split(brTagRegex)
                    .reduce((acc, item) => {
                        let [key, value] = item.split(" (");
                        if (value) {
                            value = value.replace(")", "");
                            if (mappings[key]) {
                                acc[mappings[key]] = value;
                            }
                        } else {
                            acc[mappings[key]] = "All";
                        }
                        return acc;
                    }, {});

                // Merge the transformed data into the accumulator
                Object.assign(acc, transformedData);
            } else if (item.id === "full_pool") {
                const [schedule_start, schedule_end] = item.value.split("-");
                acc["schedule_start"] = schedule_start.trim();
                acc["schedule_end"] = schedule_end.trim();
            } else if (item.id === "managedBy") {
                acc[item.id] = item.value;
                if (item?.valueLink) {
                    acc.operated_by_link = item?.valueLink;
                }
            } else {
                acc[item.id] = overviewDataMap[item.id]
                    ? overviewDataMap[item.id](item.value)
                    : item.value;
            }
            return acc;
        }, {}) || {};

    if (assembly) {
        assembly.forEach((item) => {
            if (!defaultValues[item.id]) {
                defaultValues[item.id] = null;
            }
        });
    }
    return defaultValues;
};
